<!--客户案例-->
<template>
  <div class="case-details-container">
    <new-cloude-header/>
    <div class="case-breadcrumb">
      <el-breadcrumb separator="|" style="padding: 20px 0px">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/productsService' }">客户案例</el-breadcrumb-item>
        <el-breadcrumb-item>{{ caseDetails.title }}案例</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="case-details-content">
      <div class="details-content">
        <p class="case-title">企业介绍</p>
        <p class="case-enterprise-introduce" v-if="caseDetails.customerStories.enterpriseIntroduction" v-html="caseDetails.customerStories.enterpriseIntroduction"/>
        <p class="case-title">项目目标</p>
        <p v-if="caseDetails.customerStories.projectObjectives.introduce" class="case-enterprise-introduce" v-html="caseDetails.customerStories.projectObjectives.introduce"/>
        <div class="case-ol">
          <div class="case-ul">
            <template v-if="caseDetails.customerStories.projectObjectives.list.length>0">
              <template v-for="architecture of caseDetails.customerStories.projectObjectives.list">
                <ul v-if="architecture.title!=''">
                  <li class="list-style-type">{{architecture.title}}
                    <ul>
                      <li class="none-list-style" v-html="architecture.introduce"/>
                    </ul>
                  </li>
                </ul>
                <ul v-else>
                  <li class="list-style-type">{{architecture.introduce}}</li>
                </ul>
              </template>
            </template>
          </div>
        </div>
        <p class="case-title">项目成效</p>
        <p v-if="caseDetails.customerStories.effectiveness.introduce" class="case-enterprise-introduce" v-html="caseDetails.customerStories.effectiveness.introduce"/>
        <div class="case-ol">
          <div class="case-ul">
            <template v-if="caseDetails.customerStories.effectiveness.list.length>0">
              <template v-for="effectiveness of caseDetails.customerStories.effectiveness.list">
                <ul v-if="effectiveness.title!=''">
                  <li class="list-style-type">{{effectiveness.title}}
                    <ul>
                      <li class="none-list-style" v-html="effectiveness.introduce"/>
                    </ul>
                  </li>
                </ul>
                <ul v-else>
                  <li class="list-style-type">{{effectiveness.introduce}}</li>
                </ul>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <new-cloude-footer/>
  </div>
</template>
<script>
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import solution from "@/static/solution.json";
export default {
  name: "caseDetails",
  components: {NewCloudeFooter, NewCloudeHeader},
  data() {
    return {
      id:'',
      caseDetails:{},
      industryId:'',
    }
  },
  methods:{

  },
  mounted() {
    this.id=this.$route.query.id;
    this.industryId=this.$route.query.industryId;
    for (let a of solution){
      let reg = new RegExp('\n', 'g')//g代表全部
      if (a.industryId==this.industryId){
        if (a.children.length>0){
          for (let b of a.children){
            if (b.id==this.id){
              if (b.customerStories.enterpriseIntroduction!='' && b.customerStories.enterpriseIntroduction.indexOf('\n') > -1) {
                b.customerStories.enterpriseIntroduction= b.customerStories.enterpriseIntroduction.replace(reg, '<br/>');
              }
              if (JSON.stringify(b.customerStories.projectObjectives)!='{}'){
                if (b.customerStories.projectObjectives.introduce!='' && b.customerStories.projectObjectives.introduce.indexOf('\n') > -1) {
                  b.customerStories.projectObjectives.introduce= b.customerStories.projectObjectives.introduce.replace(reg, '<br/>');
                }
                if (b.customerStories.projectObjectives.list.length>0){
                  for (let c in b.customerStories.projectObjectives.list){
                    if (b.customerStories.projectObjectives.list[c].introduce!='' && b.customerStories.projectObjectives.list[c].introduce.indexOf('\n')>-1){
                      b.customerStories.projectObjectives.list[c].introduce=b.customerStories.projectObjectives.list[c].introduce.replace(reg,'<br/>');
                    }
                  }
                }
              }
              if (JSON.stringify(b.customerStories.effectiveness)!='{}'){
                if (b.customerStories.effectiveness.introduce!='' && b.customerStories.effectiveness.introduce.indexOf('\n') > -1) {
                  b.customerStories.effectiveness.introduce= b.customerStories.effectiveness.introduce.replace(reg, '<br/>');
                }
                if (b.customerStories.effectiveness.list.length>0){
                  for (let c in b.customerStories.effectiveness.list){
                    if (b.customerStories.effectiveness.list[c].introduce!='' && b.customerStories.effectiveness.list[c].introduce.indexOf('\n')>-1){
                      b.customerStories.effectiveness.list[c].introduce=b.customerStories.effectiveness.list[c].introduce.replace(reg,'<br/>');
                    }
                  }
                }
              }
              this.caseDetails=b;
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.case-details-container{
  background: #F1F1F1;
  .case-breadcrumb{
    width: 1200px;
    margin: auto;
  }
  .case-details-content{
    background: #FFFFFF;
    padding: 30px 0px;
    .details-content{
      width: 1200px;
      margin: auto;
      .case-title{
        padding-left: 15px;
        font-family: 'SourceHanSansCN';
        font-size: 18px;
        color: #333333;
        border-left: 4px solid #1935DE;
        line-height: 18px;
        margin-block-end: 30px;
        margin-block-start: 0px;
      }
      .case-enterprise-introduce{
        font-size: 16px;
        font-family: "SourceHanSansCN";
        color: #333333;
        margin-block-end: 30px;
        line-height: 30px;
        margin-block-start: 0px;
      }
    }
    ul {
      margin-block-start: 0px;
      margin-block-end: 20px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 13px;
    }
    .none-list-style{
      list-style-type: none;
      margin-top: 5px;
    }
    .list-style-type{
      list-style-type: "◆";
      padding-left: 10px;
      color: #333333;
      font-family: 'SourceHanSansCN';
      ul {
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        font-size: 14px;
      }
    }
    .case-ul{
      overflow-y: auto;
      padding-right: 10px;
    }
    ::marker {
      color: #333333;
      unicode-bidi: isolate;
      font-variant-numeric: stacked-fractions;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
      font-size: xx-small;
    }
  }
}
</style>
